import { getIconSize } from '@/components/v2/Icons/utils';
import { SVGWrapper, SVGWrapperProps } from './SvgWrapper';

export const Pencil = (props: SVGWrapperProps): JSX.Element => {
  const iconSize = getIconSize(props.size || 'sm');
  return (
    <SVGWrapper {...props}>
      <g clipPath="url(#clip0_39_26297)">
        <path d="M1.4585 12.5416L4.69557 11.2966C4.90262 11.217 5.00614 11.1772 5.103 11.1252C5.18903 11.079 5.27105 11.0257 5.34819 10.9658C5.43503 10.8984 5.51346 10.82 5.67033 10.6631L12.2502 4.08331C12.8945 3.43898 12.8945 2.39431 12.2502 1.74997C11.6058 1.10564 10.5612 1.10564 9.91685 1.74997L3.33699 8.32981C3.18013 8.48667 3.1017 8.5651 3.03431 8.65195C2.97445 8.72909 2.92115 8.81111 2.87497 8.89714C2.82298 8.99399 2.78316 9.09752 2.70352 9.30457L1.4585 12.5416ZM1.4585 12.5416L2.65907 9.4202C2.74498 9.19683 2.78793 9.08515 2.86161 9.03399C2.926 8.98928 3.00567 8.97237 3.08267 8.98708C3.17077 9.0039 3.25539 9.08851 3.42461 9.25774L4.74241 10.5755C4.91164 10.7448 4.99625 10.8294 5.01308 10.9175C5.02778 10.9945 5.01087 11.0742 4.96616 11.1385C4.91501 11.2122 4.80332 11.2552 4.57995 11.3411L1.4585 12.5416Z" />
      </g>
      <defs>
        <clipPath id="clip0_39_26297">
          <rect width={iconSize} height={iconSize} fill="white" />
        </clipPath>
      </defs>
    </SVGWrapper>
  );
};
