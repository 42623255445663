import '@/styles/ag-grid-custom.css';

import { ClerkProvider } from '@clerk/nextjs';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { WSToastProvider, WSToastPublisher, WSToastPublisherContext } from '../components/base-widgets/ws-toast';
import { WSTooltipProvider } from '../components/base-widgets/ws-tooltip';
import { ClerkAuthContextProvider } from '../components/contexts/clerk-auth';
import { startBugsnag } from '../lib/client/bugsnag';
import { startGTM } from '../lib/client/gtm';
import { MANTINE_THEME, MANTINE_THEME_CSS_VARIABLE_RESOLVER } from '../utils/mantine';

import { SubscriptionContainer } from '@/components/common/subscription-container';
import ErrorBoundary from '@/components/v2/common/ErrorBoundary';
import { MANTINE_THEME as NEW_MANTINE_THEME } from '@/components/v2/ui';
import { Flavor, getBuildFlavor, IS_V2_UI } from '@/utils/flags';

import { useRouter } from 'next/router';
import defaultPosthog, { CaptureResult } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Inject global styles
if (process.env.NEXT_PUBLIC_UI_TYPE) {
  import('../components/v2/ui/styles/global.css');
} else {
  import('../styles/globals.css');
}

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  const postHogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;

  if (postHogKey) {
    defaultPosthog.init(postHogKey, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      // Learn more about autocapture: https://posthog.com/docs/product-analytics/autocapture
      autocapture: {
        css_selector_allowlist: ['[ph-autocapture]'],
      },
      // Enable session recording
      session_recording: {
        blockClass: 'no-record', // CSS class to block elements from being recorded
        maskTextClass: 'mask-text', // CSS class to mask text content within an element
        maskAllInputs: true, // Mask all input fields by default
        maskInputOptions: {
          password: true, // Mask password inputs
          email: true, // Mask email inputs
        },
        collectFonts: false, // Set to true if you want to collect font files
        inlineStylesheet: true, // Include external stylesheets in the recording
        recordCrossOriginIframes: true, // Enable recording for cross-origin iframes
        compress_events: true, // Compress events to save bandwidth
        recordHeaders: true, // Record request headers in network requests
        recordBody: false, // Disable recording of request bodies in network requests
        maskCapturedNetworkRequestFn: (request) => {
          // Modify or mask specific captured network request data
          // Example: Masking sensitive data
          if (request.name && request.name.includes('sensitive')) {
            return null; // Exclude this request from being recorded
          }
          return request;
        },
      },
      // Enable debug mode in development
      loaded: (posthog) => {
        posthog.register({ site_type: 'app' });

        if (getBuildFlavor() === Flavor.Local) posthog.debug();
      },
    });
  }
}

TimeAgo.addLocale(en);

const BugSnagErrorBoundary = startBugsnag();

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  // Google tag manager init
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isGtmActive = startGTM();
  }, []);

  useEffect(() => {
    // Track page views in PostHog
    const handleRouteChange = (): CaptureResult | undefined => defaultPosthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const toastContextRef = React.useRef<WSToastPublisher>(null);

  const mantineThemeProviders = IS_V2_UI
    ? {
        theme: NEW_MANTINE_THEME,
      }
    : {
        theme: MANTINE_THEME,
        cssVariablesResolver: MANTINE_THEME_CSS_VARIABLE_RESOLVER,
      };

  return (
    <BugSnagErrorBoundary>
      <ColorSchemeScript defaultColorScheme="light" />
      <MantineProvider {...mantineThemeProviders}>
        <ErrorBoundary>
          {IS_V2_UI && <Notifications />}
          <WSTooltipProvider>
            <WSToastProvider duration={10000}>
              <WSToastPublisherContext.Provider value={toastContextRef}>
                <ClerkProvider>
                  <ClerkAuthContextProvider>
                    <SubscriptionContainer>
                      <PostHogProvider client={defaultPosthog}>
                        <Component {...pageProps} />
                      </PostHogProvider>
                    </SubscriptionContainer>
                  </ClerkAuthContextProvider>
                </ClerkProvider>
              </WSToastPublisherContext.Provider>
            </WSToastProvider>
          </WSTooltipProvider>
        </ErrorBoundary>
        {/* Linter doesn't like jsx or global for some reason */}
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx global>{`
          html,
          body,
          body > div:first-child,
          div#__next,
          div#__next > div {
            min-height: 100vh;
          }
        `}</style>
      </MantineProvider>
    </BugSnagErrorBoundary>
  );
}

export default MyApp;
