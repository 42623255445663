export const coreBasesKey = '/core-bases';
export const coreBaseKey = (coreBaseId: string): string => `/core-base/${coreBaseId}`;
export const gridCoreBaseKey = (coreBaseId: string): string => `/core-base/grid/${coreBaseId}`;
export const coreBaseSyncStatusKey = (coreBaseId: string): string => `/core-base/${coreBaseId}/sync-status`;
export const externalBasesForCoreBaseKey = (coreBaseId: string): string => `/core-base/${coreBaseId}/external-bases`;
export const basePreviewsForConnectionKey = (connectionId: string): string =>
  `/core-base/${connectionId}/base-previews`;
export const syncErrorsKey = (
  page: number,
  filters: Record<string, string>,
): (string | number | Record<string, string>)[] => ['/sync-errors', page, filters];
export const syncLogsKey = (
  page: number,
  filters: Record<string, string>,
  includeAllLogTypes: boolean,
): (string | number | Record<string, string> | boolean)[] => ['/sync-logs', page, filters, includeAllLogTypes];
export const connectorInfosKey = '/connector-infos-key';
export const allBaseBundleSetupUisKey = '/all-base-bundle-setup-uis-key';
export const baseBundleSetupUisKey = (connectorType: string): string => `/base-bundle-setup-uis-key/${connectorType}`;
export const getSyncErrorType = (id: string): string => `/sync-error/${id}`;
export const getSyncLogType = (id: string): string => `/sync-log/${id}`;
export const getUserType = (id: string): string => `/user/${id}`;
export const userLimitDataKey = '/users/limit-data';
export const cmrsKey = (coreBaseId: string, coreTableId: string): string =>
  `/core-base/${coreBaseId}/core-table/${coreTableId}/core-meta-records`;
export const debugCmrKey = (remoteRecordId: string): string => `/core-meta-records/debug/${remoteRecordId}`;
export const syncPreviewKey = (syncPreviewId: string): string => `/rest/sync-preview/${syncPreviewId}`;
export const syncPreviewDuplicatesKey = (syncPreviewId: string, tableId: string): string =>
  `/rest/sync-preview/${syncPreviewId}/${tableId}/duplicates`;
export const connectionsKey = '/connections';
