import {
  ActionIcon,
  ActionIconProps,
  Button,
  Center,
  CenterProps,
  Group,
  GroupProps,
  Stack,
  Text,
  TextProps,
} from '@mantine/core';
import { PropsWithChildren } from 'react';

import { ArrowUpRight, InfoCircle, MagnifyingGlass } from '@/components/v2/Icons';

import Link from 'next/link';
import styles from './Info.module.css';

/*
 A general information panel that can be used for not-found, empty, or error states.

 Designed to fill in an area and center some content, with a title, description, and optional actions.

 Assemble the stack with an icon, title, description, and actions. 
*/
export const Info = ({ children, ...props }: CenterProps): JSX.Element => {
  return (
    <Center h="100%" w="100%" {...props}>
      <Stack align="center" maw="300px" gap="0px" className={styles.container}>
        {children}
      </Stack>
    </Center>
  );
};

const Icon = (props: ActionIconProps): JSX.Element => {
  return <ActionIcon {...props} variant="light" style={{ pointerEvents: 'none' }} mb="sm" />;
};

const ErrorIcon = (): JSX.Element => {
  return (
    <ActionIcon color="red" variant="light" style={{ pointerEvents: 'none' }} mb="sm">
      <InfoCircle />
    </ActionIcon>
  );
};

const NotFoundIcon = (): JSX.Element => {
  return (
    <ActionIcon color="gray" variant="light" style={{ pointerEvents: 'none' }} mb="sm">
      <MagnifyingGlass />
    </ActionIcon>
  );
};

const Title = ({ children, ...props }: PropsWithChildren<TextProps>): JSX.Element => {
  return (
    <Text fw={500} c="gray.11" mb="2px" {...props}>
      {children}
    </Text>
  );
};

const Description = ({ children, ...props }: PropsWithChildren<TextProps>): JSX.Element => {
  return (
    <Text fw={400} c="gray.10" {...props}>
      {children}
    </Text>
  );
};

const StatusPageDescription = (): JSX.Element => {
  return (
    <Description>
      An error has occured. If the error persists, please visit our{' '}
      <Link href="https://docs.whalesync.com/resources/support" target="_blank">
        Support Page
      </Link>
    </Description>
  );
};

/*
  A button that links to the documentation. By default it goes to the quick-start guide.
  @param link - The link to go to.
*/
const ReadDocsButton = ({ link }: { link?: string }): JSX.Element => {
  const docsLink = link || 'https://docs.whalesync.com/start-here/quick-start';
  return (
    <Button href={docsLink} leftSection={<ArrowUpRight />} variant="outline" component="a" size="sm" target="_blank">
      Read Docs
    </Button>
  );
};

const Actions = ({ children, ...props }: PropsWithChildren<GroupProps>): JSX.Element => {
  return (
    <Group gap="sm" mt="md" {...props} justify="center" align="center">
      {children}
    </Group>
  );
};

Info.Icon = Icon;
Info.NotFoundIcon = NotFoundIcon;
Info.ErrorIcon = ErrorIcon;
Info.Title = Title;
Info.Description = Description;
Info.StatusPageDescription = StatusPageDescription;
Info.Actions = Actions;
Info.ReadDocsButton = ReadDocsButton;
