import { Info } from '@/components/v2/common/Info';
import { RouteUrls } from '@/utils/route-urls';
import { Button, Group } from '@mantine/core';
import Link from 'next/link';
import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO: report to bugsnag
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render(): ReactNode {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Info>
          <Info.ErrorIcon />
          <Info.Title>Something went wrong.</Info.Title>
          <Info.Description>
            Looks like there was a error which broke the system. If the issue persists please contact the team.
          </Info.Description>

          <Info.Actions>
            <Group>
              <Button href={RouteUrls.v2Syncs} component={Link} variant="outline" size="sm">
                Return to dashboard
              </Button>

              <Button color="blue" size="sm" onClick={() => this.setState({ hasError: false })}>
                Try again
              </Button>
            </Group>
          </Info.Actions>
        </Info>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
